import React from "react";
import PropTypes from "prop-types";
import { Box, Stack, Typography, Skeleton } from "@mui/material";
import styled from "@emotion/styled";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { COLORS } from "../../constants/base";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 3,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: COLORS.green,
  },
}));

function EventStatistic({
  timeData,
  progress,
  offName,
}, ref) {
 
  return (
    <Box ref={ref} sx={{ backgroundColor: 'white', boxSizing: 'borderBox', zIndex: 100, }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
      >
        <Box>
          {timeData && timeData.total_events ? (
            <Typography component="span">
              {`${timeData.irl} task remaining`}
            </Typography>
          ) : (
            <Skeleton
              data-testid="statistic"
              variant="text"
              sx={{ fontSize: "1rem", width: 350 }}
            />
          )}
        </Box>
        <Box sx={{ display: offName ? "none" : "block" }}>
          {timeData && timeData.serial && timeData.irl ? (
            <Typography
              data-testid="site-name"
              component="span"
              variant="body1"
              sx={{ color: COLORS.accent_blue }}
            >
              {timeData.site_name
                ? `${timeData.serial} - ${timeData.site_name} - ${timeData.irl}`
                : `${timeData.serial} - ${timeData.irl}`}
            </Typography>
          ) : (
            <Skeleton
              data-testid="site-info"
              variant="text"
              sx={{ fontSize: "1rem", width: 150 }}
            />
          )}
        </Box>
      </Stack>
      <Box sx={{ width: "100%", marginTop: "10px" }}>
        <BorderLinearProgress variant="determinate" value={progress} />
      </Box>
    </Box>
  );
}

EventStatistic.propTypes = {
  timeData: PropTypes.shape({
    alt: PropTypes.number.isRequired,
    irl: PropTypes.number.isRequired,
    total_events: PropTypes.number.isRequired,
    serial: PropTypes.string,
    site_name: PropTypes.string,
  }),
  completedTasks: PropTypes.number,
  titleToEnd: PropTypes.string,
  progress: PropTypes.number.isRequired,
  offName: PropTypes.bool,
};

EventStatistic.defaultProps = {
  timeData: null,
  completedTasks: null,
  titleToEnd: null,
  offName: false,
};

export default React.forwardRef(EventStatistic);

import React, {useEffect, useState} from 'react';
import {Box, Stack} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {selectDevices} from "../../redux/devices";
import {selectSites} from "../../redux/sites";
import ReviewSelectStandard from "../inputs/ReviewSelectStandard";
import {baseAxios} from "../../utils/axios";
import {selectFilters, updateFilters} from "../../redux/filters";
import { debounce } from "@mui/material";

const WAIT = 300

const SiteDeviceFilter = () => {
  const [devices, setDevices] = useState(useSelector(selectDevices).devices);
  const { sites } = useSelector(selectSites)
  const { filters } = useSelector(selectFilters)
  const [ site, setSite] = useState(filters.site);
  const dispatch = useDispatch()

  useEffect(() => {
    const params = {
      site: site.map(({ id }) => id ),
    }
    baseAxios("api/devices/device_zones/", { params })
      .then(res => {
        if (res.data.length) {
          const selectedExist = res.data.filter(dev => filters.zone.some(({ id }) => id === dev.id))
          dispatch(updateFilters({ zone: selectedExist, site: site }))
          setDevices(res.data)
          return;
        }
        dispatch(updateFilters({ zone: [], site: site }))
      })
  }, [site]);

  const updateZone = value => dispatch(updateFilters({ zone: value }))

  return (
    <Stack spacing={4} direction="row" sx={{ flex: 1 }} justifyContent="flex-end">
      {sites.length > 1 && (
        <Box maxWidth="280px" sx={{ width: '100%' }}>
          <ReviewSelectStandard
            fullWidth
            multiple
            value={site}
            setValue={debounce(setSite, WAIT)}
            items={sites}
            variant="outlined"
            placeholder="All Sites"
          />
        </Box>
      )}
      <Box maxWidth="280px" sx={{ width: '100%' }}>
        <ReviewSelectStandard
          fullWidth
          multiple
          value={filters.zone}
          setValue={debounce(updateZone, WAIT)}
          items={devices}
          variant="outlined"
          placeholder="All Devices"
        />
      </Box>
    </Stack>
  );
};

export default SiteDeviceFilter;

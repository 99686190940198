import { enqueueSnackbar } from "notistack";
import dayjs from "dayjs";
import { baseAxios } from "../../../utils/axios";

const correctEvent = async ({ eventId, user, setLeftTime, reset, trimmings }) => {
  const url = `api/verification/${eventId}`;

  const data = {
    verify: true,
    verified_at: dayjs(),
    user_verify: user.id,
    trimmings,
  };

  const response = await baseAxios.patch(url, data);

  if (response.status === 200) {
    reset();
    enqueueSnackbar("Event was successfully updated.", {
      variant: "success",
      autoHideDuration: 1500,
    });
  } else {
    enqueueSnackbar("Oops, something went wrong.", {
      variant: "error",
      autoHideDuration: 1500,
    });
  }
};

export default correctEvent;

import React from "react";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";

import ImageBlock from "../components/ImageBlock/ImageBlock";

function ImageSection({
  md,
  leftEvent,
  rightEvent,
  device,
  loadingLeftEvent,
  setLoadingLeftEvent,
  loadingRightEvent,
  setLoadingRigthEvent,
  setSelectedIds,
  setOpenHandle,
  setIsFetch,
  setSelectedImage,
  isDelete
}) {
  return (
    <Stack
      direction={md ? "column" : "row"}
      justifyContent={md ? "center" : "space-between"}
      alignItems="center"
      spacing={8}
      sx={{ margin: "20px 0 20px 0" }}
    >
      <ImageBlock
        isFirst
        event={leftEvent}
        device={device}
        loading={loadingLeftEvent}
        setLoading={setLoadingLeftEvent}
        setIsFetch={setIsFetch}
      />
      <ImageBlock
        isDelete={isDelete}
        event={rightEvent}
        device={device}
        loading={loadingRightEvent}
        setLoading={setLoadingRigthEvent}
        setIsFetch={setIsFetch}
        setSelectedIds={setSelectedIds}
        handlerOpen={setOpenHandle}
        setSelectedImage={setSelectedImage}
      />
    </Stack>
  );
}

ImageSection.propTypes = {
  md: PropTypes.bool.isRequired,
  leftEvent: PropTypes.shape({
    auto_label: PropTypes.bool.isRequired,
    backup_image_url: PropTypes.arrayOf(PropTypes.string),
    device_serial: PropTypes.string.isRequired,
    food: PropTypes.number,
    food_name: PropTypes.string,
    id: PropTypes.number.isRequired,
    image_url: PropTypes.string,
    item_weight: PropTypes.string,
    raw_weight: PropTypes.string,
    status: PropTypes.string.isRequired,
    labelled_at: PropTypes.string.isRequired,
    logged_at: PropTypes.string.isRequired,
    processed_at: PropTypes.string.isRequired,
    trimmings: PropTypes.bool.isRequired,
    user: PropTypes.number,
  }),
  rightEvent: PropTypes.shape({
    auto_label: PropTypes.bool.isRequired,
    backup_image_url: PropTypes.arrayOf(PropTypes.string),
    device_serial: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    image_url: PropTypes.string,
    item_weight: PropTypes.string,
    raw_weight: PropTypes.string,
    status: PropTypes.string.isRequired,
    logged_at: PropTypes.string.isRequired,
    processed_at: PropTypes.string.isRequired,
  }),
  device: PropTypes.shape({
    alt: PropTypes.number,
    irl: PropTypes.number,
    total_events: PropTypes.number,
  }),
  loadingLeftEvent: PropTypes.bool.isRequired,
  loadingRightEvent: PropTypes.bool.isRequired,
  setLoadingRigthEvent: PropTypes.func.isRequired,
  setLoadingLeftEvent: PropTypes.func.isRequired,
  setSelectedIds: PropTypes.func,

  setOpen: PropTypes.func,
  setIsFetch: PropTypes.func,
  setOpenHandle: PropTypes.func,
};

ImageSection.defaultProps = {
  leftEvent: null,
  rightEvent: null,
  device: null,
  setSelectedIds: () => {},
  setOpen: () => {},
  setIsFetch: () => {},
  setOpenHandle: () => {},
};

export default ImageSection;

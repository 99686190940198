import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Button as MuiButton, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import SelectFood from "./SelectFood";
import WasteButton from "./WasteButton";
import { CustomCheckbox } from "../styled/GlobalStyled";

const Button = styled(MuiButton)(spacing);

function WasteSection({
  food,
  setFood,
  foodOpen,
  setFoodOpen,
  select,
  selectOpen,
  setSelectOpen,
  handleChange,
  isDisabled,
  handlerSubmit,
  foods,
  isTrimmings,
}) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      justifyContent="flex-end"
      alignItems={isMobile ? "flex-end" : "center"}
      spacing={4}
      data-testid="testid"
    >
      <SelectFood
        food={food}
        setFood={setFood}
        open={foodOpen}
        setOpen={setFoodOpen}
        options={foods}
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
       <FormControlLabel
            checked={isTrimmings}
            control={
              <CustomCheckbox
                sx={{ padding: "0 11px" }}
                name="trimmings"
                onChange={handleChange}
              />
            }
            label="Trimmings"
          />
        <WasteButton
          select={select}
          selectOpen={selectOpen}
          setSelectOpen={setSelectOpen}
          handleChange={handleChange}
          isDisabled={isTrimmings}
        />
        <Button
          data-testid="submit-button"
          variant="contained"
          disabled={isDisabled}
          style={{
            marginLeft: "15px",
            maxWidth: isMobile ? "100px" : "160px",
            minWidth: isMobile ? "100px" : "160px",
            maxHeight: "42px",
            minHeight: "42px",
          }}
          onClick={handlerSubmit}
        >
          <Typography variant="button">{t("Submit")}</Typography>
        </Button>
      </Stack>
    </Stack>
  );
}

WasteSection.propTypes = {
  food: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      is_recipe: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setFood: PropTypes.func.isRequired,
  foodOpen: PropTypes.bool.isRequired,
  setFoodOpen: PropTypes.func.isRequired,
  select: PropTypes.string.isRequired,
  selectOpen: PropTypes.bool.isRequired,
  setSelectOpen: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handlerSubmit: PropTypes.func.isRequired,
};

export default WasteSection;

import React, {useRef, useState} from 'react';
import FormLayout from "../../components/auth/FormLayout";
import {Stack} from "@mui/material";
import Button from "@mui/material/Button";
import {COLORS} from "../../constants/base";
import {useFormik} from "formik";
import useAppSelector from "../../hooks/useAppSelector";
import {selectUser} from "../../redux/user";
import useAppDispatch from "../../hooks/useAppDispatch";
import {CUSTOM_ANSWER, ONBOARDING_QUESTIONS} from "../../components/onboarding/questions";
import {FIELD_KEY, renderHandlerByType} from "../../components/onboarding/handlers";
import * as Yup from "yup";
import userService from '../../services/UserService'
import {useSnackbar} from "notistack";
import {errorMessage} from "../../utils/axios";

const initialValues = {
    [FIELD_KEY]: '',
    [CUSTOM_ANSWER]: '',
  }
const Onboarding = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const answers = useRef({})
  const user = useAppSelector(selectUser)
  const dispatch = useAppDispatch()
  const currentQuestion = ONBOARDING_QUESTIONS[currentIndex]
  const isLastQuestion = ONBOARDING_QUESTIONS.length === currentIndex + 1
  const { enqueueSnackbar } = useSnackbar()

  const finishOnboarding = (payload = answers) => dispatch(
    userService.updateUser(user.id, {...payload, is_onboarded: true})
  ).catch((error) => enqueueSnackbar(errorMessage(error), { variant: "error"}))

  const proccessNext = (payload) => {
    if(isLastQuestion) {
      finishOnboarding(payload)
    }else {
      const nextQuestion = ONBOARDING_QUESTIONS[currentIndex + 1]
      const existAnswer = nextQuestion && answers.current[nextQuestion.field]
      const state = existAnswer
      ? {[FIELD_KEY]: existAnswer}
      : initialValues
      setCurrentIndex(i => ++i)
      resetForm({ values: state })
    }
  }

  const next = ({ answer, [CUSTOM_ANSWER]: customAnswer }) => {
    const answerToSend =  customAnswer || answer
    const updatedAnswers = {...answers.current, [currentQuestion.field]: answerToSend }
    answers.current = updatedAnswers
    proccessNext(updatedAnswers)
  }
  const { handleChange, handleSubmit, resetForm, values, errors, setErrors, setFieldValue } = useFormik({
    initialValues,
    onSubmit: next,
    validationSchema: Yup.object().shape({
      answer: currentQuestion.validation,
      [CUSTOM_ANSWER]: currentQuestion.validation && Yup.string().when('answer', {
        is: currentQuestion.customAnswerOn,
        then: currentQuestion.validation,
      })
    }),
    validateOnChange: false,
  })
  
  const skip = () => {
    if (answers.current[currentQuestion.field]) {
      const {[currentQuestion.field]: currentAnswer, ...rest} = answers.current
      answers.current = rest
    }
    proccessNext()
  }

  const goBack = () => {
    const prevQuestion = ONBOARDING_QUESTIONS[currentIndex - 1]
    setCurrentIndex(i => --i)
    resetForm({ values: {[FIELD_KEY]: answers.current[prevQuestion.field] || ''}})
  }

  return (
    <FormLayout showBackButton={!!currentIndex} onBackButtonClick={goBack} title="Firstly, a few questions" headTitle="Onboarding">
      <form onSubmit={handleSubmit}>
        <Stack spacing={5}>
          {renderHandlerByType(currentQuestion, values[FIELD_KEY], handleChange, errors, setFieldValue, setErrors)[currentQuestion.type]}
          <Button key={currentIndex} disabled={!values[FIELD_KEY]} type='submit' variant="contained" color="secondary">
            {isLastQuestion ? 'Done' : "Next →"}
          </Button>
          {currentQuestion.canSkip && (
            <Button
              type="button"
              sx={{
                color: COLORS.darkBlue2,
                fontSize: "13px",
                fontWeight: 400,
                padding: 0,
                textDecoration: 'underline',
                alignSelf: 'flex-start',
              }}
              onClick={skip}
            >
              Skip this Question
            </Button>
          )}
        </Stack>
      </form>
    </FormLayout>
  );
};

export default Onboarding;
